<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      :locale="locale"
      :show-operations="false"
      no-meta-check
      route-base="/resources/devices"
      resource="devices"
      sortable
      show-search-filter
      :resource-query="{ query: { deleted: false } }"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import isEmpty from 'just-is-empty'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.devices.title')
    }
  },
  data() {
    return {
      resources: {},
      bindActionOpen: false,
      createActionOpen: false,
      statuses: {
        free: this.$t('pages.devices.edit.form.free.label'),
        bound: this.$t('pages.devices.edit.form.bound.label')
      },
      types: {
        cfd: this.$t('pages.devices.edit.form.cfd.label'),
        eda: this.$t('pages.devices.edit.form.eda.label')
      },
      headers: [
        {
          field: 'name',
          label: this.$t('pages.devices.all.headers.name'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          minWidth: 180,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !row.type) return '-'
            return this.types[row.type] || '-'
          }
        },
        {
          field: 'location',
          label: this.$t('common.headers.location.title'),
          minWidth: 180,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !row.register) return '-'

            let register
            let branch
            register = (this.resources.registers || []).find(
              (item) => item.id === row.register
            )
            if (register) {
              branch = (this.resources.branches || []).find(
                (item) => item.id === register.branch
              )
            }

            if (branch || register) {
              return `${branch.name || ''}${branch && register ? ' - ' : ''}${
                register.register_number || ''
              }`.trim()
            }

            return '-'
          }
        },
        {
          field: 'status',
          label: this.$t('pages.devices.all.headers.status'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            if (!row || !row.status) return '-'
            return this.statuses[row.status] || '-'
          }
        },
        {
          field: 'has_content',
          label: this.$t('pages.devices.all.headers.has_contents.title'),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          formatter: (row, column) => {
            if (!row || !row.contents || isEmpty(row.contents)) return '-'
            return this.$t('pages.devices.all.headers.has_contents.yes')
          }
        },
        {
          field: 'online_status',
          label: this.$t('pages.devices.all.headers.online_status.title'),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          formatter: (row, column) => {
            // no need to show status of a "free" device
            if (!row.device_id) return

            const timeDiff = Date.now() - Date.parse(row.updated_at)

            if (timeDiff < 120000) {
              // 2 minutes
              return this.$t('pages.devices.all.headers.online_status.online')
            }

            if (timeDiff < 1800000) {
              // 30 minutes
              return this.$t('pages.devices.all.headers.online_status.unknown')
            }

            return this.$t('pages.devices.all.headers.online_status.offline')
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation'
    })
  },
  watch: {
    currentLocation: 'fetchResources'
  },
  async beforeMount() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.devices.title')
        })
      })
    },
    async fetchResources() {
      const res = await this.$resourceFetch('branchesV1', {
        resource: 'registers',
        query: { branch: this.currentLocation }
      })
      this.resources = { branches: res.branchesV1, registers: res.registers }
    }
  }
}
</script>

<style scoped></style>
